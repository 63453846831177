<template>
  <div>
    <v-container id="typography" fluid tag="section">
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-sheet>
            <template v-slot:heading>
              <div class="text-h3 font-weight-light">Routes</div>
            </template>

            <v-card-text>
              <pre>{{ $router.options.routes }}</pre>
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  head() {
    return { title: "Ehitussektor.ee" };
  },
};
</script>
