<template>
  <div>
    <v-container id="typography" fluid tag="section">
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-sheet>
            <template v-slot:heading>
              <div class="text-h3 font-weight-light">Typographies</div>

              <div class="text-subtitle-1 font-weight-light">
                <p>h1 h2 ... tags does'nt have any styles - use these for semantic organization.</p>
              </div>
            </template>

            <v-card-text>
              <div class="my-3">
                <p class="text--primary mr-4">
                  <span class="black--text mr-4" style>.text--primary</span>
                  <br />
                  High-emphasis has an opacity of 87%.
                </p>
                <p class="text--secondary">
                  <span class="black--text mr-4" style>.text--secondary</span>
                  <br />
                  Medium-emphasis text and hint text have opacities of 60%.
                </p>
                <p class="text--disabled">
                  <span class="black--text mr-4" style>.text--disabled</span>
                  <br />
                  Disabled text has an opacity of 37%.
                </p>
              </div>
              <v-divider></v-divider>
              <v-container class="pa-0" fluid>
                <v-row v-for="(t, i) in typography" :key="i" align="center" justify="center">
                  <v-col cols="12" md="3">
                    <span class="tim-note" v-text="t[0]" />
                  </v-col>

                  <v-col cols="8">
                    <component :is="t[2]" :class="i">
                      <template v-if="i !== 'quote'">{{ t[1] }}</template>

                      <p v-if="i === 'quote'">{{ t[1] }}</p>
                      <small v-if="i === 'quote'">Kanye West, Musician</small>

                      <template v-if="i === 'small'">
                        <br />
                        <small>Use 'small' tag for the headers</small>
                      </template>
                    </component>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-sheet>
        
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const leader =
  "I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at.";
const leaderShort = leader.slice(0, 105) + "...";
const material = "Dressed for Strange Mistake That Ended Without Results";
const small = "Header with small subtitle";

export default {
  head() {
    return { title: "" + this.$store.getters.setting("meta_brand") };
  },
  data: () => ({
    typography: {
      "text-h1": [".text-h1", material, "h1"],
      "text-h2": [".text-h2", material, "h2"],
      "text-h3": [".text-h3", material, "h3"],
      "text-h4": [".text-h4", material, "h4"],
      "text-h5": [".text-h5", material, "h5"],
      "text-h6": [".text-h6", material, "h6"],
      title: [".title", material, "h6"],
      "text-subtitle-1": [".text-subtitle-1", material, "p"],
      "text-subtitle-2": [".text-subtitle-2", material, "p"],
      //   "": ["<p>", leader, "p"],
      "text-body-1": [".text-body-1", leader, "p"],
      "text-body-2": [".text-body-2", leader, "p"],
      //   button: [".button", material, "p"],
      //   caption: [".caption", material, "p"],
      //   overline: [".overline", material, "p"],
      blockquote: ["<blockquote>", leader, "blockquote"],
      small: ["<small>", small, "h2"],
      "text-uppercase": [".text-uppercase", material, "p"],
      "text--disabled": [".text--disabled", leaderShort, "p"],
      "primary--text": [".primary--text", leaderShort, "p"],
      "info--text": [".info--text", leaderShort, "p"],
      "success--text": [".success--text", leaderShort, "p"],
      "warning--text": [".warning--text", leaderShort, "p"],
      "danger--text": [".danger--tex", leaderShort, "p"],
    },
  }),
};
</script>

<style lang="scss">
.tim-note {
  bottom: 10px;
  color: #000;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  width: 260px;
}
</style>
